export default {
  data() {
    return {
      productsList: [
        {
          title: 'Встречи',
          descr: 'Для быстрых встреч, планерок, совещаний',
          href: '/products/meetings/',
          disable: null,
        },
        {
          title: 'Вебинары',
          descr: 'Для обучения и\u00A0маркетинга',
          href: '/products/webinar/',
          disable: null,
        },
        {
          title: 'Курсы',
          descr: 'Для онлайн-курсов и\u00A0смешанного обучения',
          href: '/products/westudy/',
          disable: null,
        },
        {
          title: 'Чаты',
          descr: 'Для оперативного общения в\u00A0мессенджере',
          href: '/products/messenger/',
          badge: 'Новый продукт',
          disable: null,
        },
        {
          title: 'Доcки',
          descr: 'Для совместной работы и\u00A0творчества команд',
          href: '/products/boards/',
          disable: null,
        },
        {
          title: 'Comdi',
          descr: 'Для крупных гибридных и\u00A0онлайн-мероприятий',
          href: '/products/comdi/',
          disable: null,
        },
      ],
    }
  },
}

<template>
  <FaqDesktop :title="title" />
</template>
<script>
const FaqDesktop = () => import(
    /* webpackChunkName: "FaqDesktop" */
    '~/components/Desktop/Common/FaqDesktop.vue'
  );
export default {
  name: 'LazyFaqDesktop',
  components: {
    FaqDesktop,
  },
  props: {
    title: {
      type: Boolean,
      default: false,
    }
  },

}
</script>

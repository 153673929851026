<template>
  <PremiseMobile />
</template>
<script>
const PremiseMobile = () => import(
    /* webpackChunkName: "PremiseMobile" */
    '~/components/Mobile/Main/PremiseMobile.vue'
  );
export default {
  name: 'LazyPremiseMobile',
  components: {
    PremiseMobile,
  }
}
</script>

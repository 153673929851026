<template>
  <div class="promo page-section">
    <div class="promo__content">
      <h1 class="promo__title">
        Экосистема сервисов для&nbsp;бизнес&#8209;коммуникаций и&nbsp;совместной работы
      </h1>
      <div class="promo__controls">
        <slot name="controls"></slot>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'PromoMobile',
}
</script>
<style lang="scss" scoped>
.promo {
  background: $color-white;
  padding: 32px 20px;
  border-radius: 0 0 24px 24px;

  &__content {
    text-align: center;
    color: $color-dark-2;
  }

  &__title {
    font-family: $font-mts-wide;
    font-size: 23px;
    font-weight: 400;
    line-height: 30px;
  }


  &__controls {
    margin: 32px auto 0px;

    &_btn.--xl {
      width: 100%;
    }
  }
}
</style>

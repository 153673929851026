<template>
  <ServicesDesktop />
</template>
<script>
const ServicesDesktop = () => import(
    /* webpackChunkName: "ServicesDesktop" */
    '~/components/Desktop/Main/ServicesDesktop.vue'
  );
export default {
  name: 'LazyServicesDesktop',
  components: {
    ServicesDesktop,
  }
}
</script>

<template>
  <ExperienceDesktop />
</template>
<script>
const ExperienceDesktop = () => import(
    /* webpackChunkName: "ExperienceDesktop" */
    '~/components/Desktop/Main/ExperienceDesktop.vue'
  );
export default {
  name: 'LazyExperienceDesktop',
  components: {
    ExperienceDesktop,
  }
}
</script>

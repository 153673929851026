<template>
  <a v-if="$attrs.href" class="v-card-mts" v-on="$listeners">
    <slot></slot>
  </a>
  <div v-else class="v-card-mts" v-on="$listeners">
    <slot></slot>
  </div>
</template>
<script>

export default {
  name: 'VCardMTS',
}
</script>
<style lang="scss">
.v-card-mts {
  $card: &;

  display: flex;
  flex-direction: column;
  width: 100%;
  height: auto;
  border: none;

  &__title {
    font-weight: 500;
  }

  &.--violet-gradient {
    background: linear-gradient(0deg, #F5D8FF -55.94%, #9657E2 57.84%, #8743DD 79.98%, #8743DD 82.2%);
  }

  &.--violet-light {
    background: #FAF6FF;
  }

  &.--violet {
    background: #8743DD;
    color: $color-white;
  }

  &.--lavander {
    background: $color-lavander;
  }


  &.--grey {
    background: #F2F3F7;
  }

  &.--black {
    background: #1d2023;
    color: $color-white;
  }

  &.--dark-grey {
    background: #1D1A20;
    color: $color-white;
  }

  &.--yellow {
    background: #ECFF9F;
  }

  &.--white {
    background: #fff;
  }

  &.--xl {
    padding: 48px;
    border-radius: 16px;
    font-size: 24px;
    line-height: 32px;

    #{$card}__title {
      font-size: 56px;
      line-height: 56px;
    }
  }

  &.--l {
    padding: 32px;
    border-radius: 16px;
    font-size: 24px;
    line-height: 32px;

    #{$card}__title {
      font-size: 36px;
      line-height: 40px;
    }

  }

  &.--m {
    padding: 32px;
    border-radius: 16px;
    font-size: 17px;
    line-height: 24px;

    #{$card}__title {
      font-size: 24px;
      line-height: 28px;
    }

  }

  &.--s {
    padding: 24px;
    border-radius: 16px;
    font-size: 14px;
    line-height: 20px;

    #{$card}__title {
      font-size: 20px;
      line-height: 24px;
    }
  }

  &.--xs {
    padding: 20px;
    border-radius: 16px;
    font-size: 14px;
    line-height: 20px;

    #{$card}__title {
      font-size: 20px;
      line-height: 24px;
    }
  }
}
</style>

<template>
  <ReviewsMobile :title="title" :color-card="colorCard" :reviews-list="reviewsList" />
</template>
<script>

const ReviewsMobile = () => import(
    /* webpackChunkName: "ReviewsMobile" */
    '~/components/Mobile/Common/ReviewsMobile.vue'
  );
export default {
  name: 'LazyReviewsMobile',
  components: {
    ReviewsMobile,
  },
  props: {
    title: {
      type: String,
      default: ''
    },
    reviewsList: {
      type: Array,
      default: () => []
    },
    colorCard: {
      type: String,
      default: ''
    }
  },

}
</script>

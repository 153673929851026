<template>
  <ClientsSliderMobile :substrate-color="substrateColor" />
</template>
<script>
const ClientsSliderMobile = () => import(
    /* webpackChunkName: "FaqMobile" */
    '~/components/Common/ClientsSlider.vue'
  );
export default {
  name: 'LazyClientsSliderMobile',
  components: {
    ClientsSliderMobile,
  },
  props: {
    substrateColor: {
      type: String,
      default: ''
    }
  }
}
</script>

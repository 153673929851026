<template>
  <BlockWithNumbersMobile>
    <slot></slot>
  </BlockWithNumbersMobile>
</template>
<script>
const BlockWithNumbersMobile = () => import(
    /* webpackChunkName: "BlockWithNumbersMobile" */
    '~/components/Mobile/Main/BlockWithNumbersMobile.vue'
  );
export default {
  name: 'LazyBlockWithNumbersMobile',
  components: {
    BlockWithNumbersMobile,
  }
}
</script>

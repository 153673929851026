<template>
  <BlockWithNumbersDesktop>
    <slot></slot>
  </BlockWithNumbersDesktop>
</template>
<script>
const BlockWithNumbersDesktop = () => import(
    /* webpackChunkName: "BlockWithNumbersDesktop" */
    '~/components/Desktop/Main/BlockWithNumbersDesktop.vue'
  );
export default {
  name: 'LazyBlockWithNumbersDesktop',
  components: {
    BlockWithNumbersDesktop,
  }
}
</script>

<template>
  <FaqMobile :title="title"/>
</template>
<script>
const FaqMobile = () => import(
    /* webpackChunkName: "FaqMobile" */
    '~/components/Mobile/Common/FaqMobile.vue'
  );
export default {
  name: 'LazyFaqMobile',
  components: {
    FaqMobile,
  },
  props: {
    title: {
      type: Boolean,
      default: false,
    }
  }
}
</script>

<template>
  <PremiseDesktop />
</template>
<script>
const PremiseDesktop = () => import(
    /* webpackChunkName: "PremiseDesktop" */
    '~/components/Desktop/Main/PremiseDesktop.vue'
  );
export default {
  name: 'LazyPremiseDesktop',
  components: {
    PremiseDesktop,
  }
}
</script>

<template>
  <div class="products">
    <div class="products__card-list">
      <VCardMTS v-for="(product, idx) in productsList" :key="idx" :data-badge="product.badge" :href="product.href"
        :class="['--xs', '--white', 'products__card', { '--with-badge': product.badge }]">
        <div class="products__card-title v-card-mts__title">{{ product.title }}
        </div>
        <div class="products__card-text v-card-mts__text">{{ product.descr }}</div>
      </VCardMTS>
    </div>
  </div>
</template>
<script>
import ProductsMixin from "~/components/Desktop/Main/mixins/ProductsMixin";
import VCardMTS from "~/components/Common/MTS/VCardMTS.vue";
export default {
  name: 'ProductsDesktop',
  components: { VCardMTS },
  mixins: [ProductsMixin],
}
</script>
<style lang="scss" scoped>
.products {
  padding: 32px 0;

  &__card {
    color: #969FA8;
    text-decoration: none;

    ::v-deep(.v-card-mts__text) {
      font-size: 12px;
      line-height: 16px;
      font-weight: 500;
    }

    &-list {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-auto-rows: 161px;
      gap: 20px;
    }

    &-title {
      color: $color-dark-2;
    }

    &-text {
      margin-bottom: 0;
      margin-top: auto;
    }

    &.--with-badge {
      position: relative;

      &::before {
        content: attr(data-badge);
        display: block;
        position: absolute;
        top: 0;
        transform: translateY(-50%);
        padding: 2px 4px;
        background: #ECFF9F;
        border-radius: 4px;
        color: $color-dark-2;
        font-size: 12px;
        font-weight: 500;
        line-height: 16px;
        text-align: center;
      }
    }
  }
}
</style>

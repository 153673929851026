<template>
  <ServicesMobile />
</template>
<script>
const ServicesMobile = () => import(
    /* webpackChunkName: "ServicesMobile" */
    '~/components/Mobile/Main/ServicesMobile.vue'
  );
export default {
  name: 'LazyServicesMobile',
  components: {
    ServicesMobile,
  }
}
</script>

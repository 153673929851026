export default {
  data() {
    return {
      ReviewsList: [
        {
          imgHref: 'main/main--reviews/reviews-author-1',
          text: `Платформа Вебинары от\u00A0МТС\u00A0Линк является для\u00A0нас действительно комплексным и\u00A0эффективным решением, способствующим росту качества работы с\u00A0целевой аудиторией. Отдельно хотелось\u00A0бы\u00A0отметить платформу Курсы, которая открыла для\u00A0нас новые продуктивные форматы работы.`,
          author: {
            name: 'Екатерина Анашкина',
            position: `Руководитель центра\u000Aпродвижения ГК\u00A0«Просвещение»`,
          },
        },
        {
          imgHref: 'main/main--reviews/reviews-author-2',
          text: `Платформа МТС\u00A0Линк\u00A0Вебинары выполняет свою главную функцию: помогает нам достучаться до\u00A0аудитории со\u00A0всей России, проще и\u00A0доступнее коммуницировать с\u00A0клиентами и\u00A0коллегами. В\u00A0целом, функционал платформы достаточно широк для\u00A0вебинарной площадки и\u00A0при\u00A0этом понятен и\u00A0легок в\u00A0использовании.`,
          author: {
            name: 'Ксения Демченко',
            position: `B2B Event Marketing Manager\u000Aкомпании «Авито»`,
          },
        },
        {
          imgHref: 'main/main--reviews/reviews-author-3',
          text: `Дистанционный формат позволяет собрать большую распределенную команду с\u00A0минимальными временными затратами. Запуск онлайн\u2011тренингов на МТС\u00A0Линк позволил нам проводить намного больше обучающих вебинаров в\u00A0единицу времени.`,
          author: {
            name: 'Оксана Субботина',
            position: `Менеджер образовательных проектов компании HeadHunter`,
          },
        },
      ],
    }
  },
}

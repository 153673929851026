<template>
  <ExperienceMobile />
</template>
<script>
const ExperienceMobile = () => import(
    /* webpackChunkName: "ExperienceMobile" */
    '~/components/Mobile/Main/ExperienceMobile.vue'
  );
export default {
  name: 'LazyExperienceMobile',
  components: {
    ExperienceMobile,
  }
}
</script>

<template>
  <div class="products">
    <div class="products__card-list" @mouseleave="resetState">
      <VCardMTS v-for="(product, idx) in productsList" :key="idx" :data-badge="product.badge" :href="product.href"
        :class="['--xs', '--white', 'products__card', product.badge ? '--with-badge' : '', { '--disable': product.disable }, { '--active': !product.disable && product.disable !== null }]"
        @mouseover="activationOnHover(idx)">
        <div class="products__card-title v-card-mts__title">{{ product.title }}
        </div>
        <div class="products__card-text v-card-mts__text">{{ product.descr }}</div>
      </VCardMTS>
    </div>
  </div>
</template>
<script>
import ProductsMixin from "~/components/Desktop/Main/mixins/ProductsMixin";
import VCardMTS from "~/components/Common/MTS/VCardMTS.vue";
export default {
  name: 'ProductsDesktop',
  components: { VCardMTS },
  mixins: [ProductsMixin],
  methods: {

    activationOnHover(idx) {
      this.productsList.forEach((product, idxForEach) => {
        if (idxForEach === idx) {
          product.disable = false
        } else {
          product.disable = true
        }
      });
    },
    resetState() {
      this.productsList.forEach((product) => {
        product.disable = null
      })
    }
  },
}
</script>
<style lang="scss" scoped>
.products {
  padding: 32px 0;

  &__card {
    $card: &;
    color: #969FA8;
    text-decoration: none;
    transition: all 0.275s ease;


    &:not(:last-child) {
      margin-right: 32px;
    }

    ::v-deep(.v-card-mts__text) {
      font-size: 12px;
      line-height: 16px;
      font-weight: 500;
    }

    &-list {
      display: grid;
      grid-template-columns: repeat(6, 1fr);
      gap: 32px;

      @include screen-down('xl') {
        grid-template-columns: repeat(3, 1fr);
        gap: 12px;
      }
    }

    &-title {
      color: $color-dark-2;
      padding-bottom: 32px;
    }

    &.--with-badge {
      position: relative;

      &::before {
        content: attr(data-badge);
        display: block;
        position: absolute;
        top: 0;
        transform: translateY(-50%);
        padding: 2px 4px;
        background: #ECFF9F;
        border-radius: 4px;
        color: $color-dark-2;
        font-size: 12px;
        font-weight: 500;
        line-height: 16px;
        text-align: center;
        transition: all 0.275s ease;
      }
    }

    &.--disable {
      background: #F8F8FB;
      color: #969FA8;

      &.--with-badge::before {
        background: #E2E5EB;
        color: #F8F8FB;

      }

      #{$card}-title {
        color: #969FA8;
      }
    }

    &.--active {
      color: $color-dark-2;
    }
  }
}
</style>

<template>
  <AdvantagesMobile :context="context" />
</template>
<script>
const AdvantagesMobile = () => import(
    /* webpackChunkName: "AdvantagesMobile" */
    '~/components/Mobile/Main/AdvantagesMobile.vue'
  );
export default {
  name: 'LazyAdvantagesMobile',
  components: {
    AdvantagesMobile,
  },
  props: {
    context: {
      type: Object,
    }
  },
}
</script>

<template>
  <AdvantagesDesktop :content="content" />
</template>
<script>
const AdvantagesDesktop = () => import(
    /* webpackChunkName: "BlockWithSwiperDesktop" */
    '~/components/Desktop/Main/AdvantagesDesktop.vue'
  );
export default {
  name: 'LazyAdvantagesDesktop',
  components: {
    AdvantagesDesktop,
  },
  props: {
    content: {
      type: Object,
    },
  },
}
</script>

<template>
  <div class="promo page-section">
    <div class="promo__content">
      <h1 class="promo__title">
        Экосистема сервисов для&nbsp;бизнес&#8209;коммуникаций и&nbsp;совместной работы
      </h1>
      <div class="promo__controls">
        <slot name="controls"></slot>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'PromoDesktop',
}
</script>
<style lang="scss" scoped>
.promo {
  background: $color-white;
  padding: 64px 0 48px;
  border-radius: 0 0 24px 24px;

  @include screen-down('lg') {
      padding: 40px 0;
  }

  &__content {
    max-width: 792px;
    text-align: center;
    margin: 0 auto;
    color: $color-dark-2;
  }

  &__title {
    font-family: $font-mts-wide;
    font-size: 55px;
    font-weight: 400;
    line-height: 55px;
    text-align: center;

    @include screen-down('lg') {
      font-size: 44px;
      line-height: 50px;
    }
  }


  &__controls {
    display: flex;
    justify-content: center;
    margin: 56px auto 0px;

    &_btn {
      width: 50%;
      max-width: 345px;
      z-index: 1;

      &:first-child {
        order: 0;
        margin-right: 16px;
      }
    }
  }
}
</style>

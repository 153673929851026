<template>
  <TaskMobile />
</template>
<script>
const TaskMobile = () => import(
    /* webpackChunkName: "TaskMobile" */
    '~/components/Mobile/Main/TaskMobile.vue'
  );
export default {
  name: 'LazyTaskMobile',
  components: {
    TaskMobile,
  }
}
</script>

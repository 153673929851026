<template>
  <ReviewsDesktop :title="title" :color-card="colorCard" :reviews-list="reviewsList" />
</template>
<script>

const ReviewsDesktop = () => import(
    /* webpackChunkName: "ReviewsDesktop" */
    '~/components/Desktop/Common/ReviewsDesktop.vue'
  );
export default {
  name: 'LazyReviewsDesktop',
  components: {
    ReviewsDesktop,
  },
  props: {
    title: {
      type: String,
      default: 'Что о&nbsp;нас говорят клиенты'
    },
    reviewsList: {
      type: Array,
      default: () => []
    },
    colorCard: {
      type: String,
      default: ''
    }
  },

}
</script>

<template>
  <div>
    <component :is="pageComponent"/>
    <div style="font-size: 0;">
      <ol itemscope itemtype="http://schema.org/BreadcrumbList">
        <li itemprop="itemListElement" itemscope
            itemtype="http://schema.org/ListItem">
          <a itemprop="item" href="/">
            <span itemprop="name">Главная</span></a>
          <meta itemprop="position" content="1"/>
        </li>
        <li itemprop="itemListElement" itemscope
            itemtype="http://schema.org/ListItem">
          <a itemprop="item" href="https://mts-link.ru/">
            <span itemprop="name">mts-link.ru</span></a>
          <meta itemprop="position" content="2"/>
        </li>
      </ol>
    </div>
  </div>
</template>

<script>
import LayoutPrimary from "@/mixins/LayoutPrimary";
import MainDesktop from "~/components/Page/Main/MainDesktop.vue";
import MainMobile from "~/components/Page/Main/MainMobile.vue";

export default {
  name: 'IndexPage',
  mixins: [LayoutPrimary],
  head() {
    return {
      bodyAttrs: {
        class: 'grey-body',
      },
    };
  },
  computed: {
    pageComponent() {
      return this.$device.isDesktop ? MainDesktop : MainMobile;
    }
  },
}
</script>
